(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/common/assets/javascripts/status-util.js') >= 0) return;  svs.modules.push('/components/racing/common/assets/javascripts/status-util.js');

'use strict';

const {
  drawStates,
  raceDrawStates
} = svs.isServer ? require('./constants.es6') : svs.racing.common.constants;

const drawOpen = draw => draw.drawState === drawStates.OPENED;
const drawNotYetOpen = draw => draw.drawState < raceDrawStates.OPENED;

const raceMoreThanDefined = race => race.raceDrawState > raceDrawStates.DEFINED;
const raceNotYetOpen = race => race.raceDrawState < raceDrawStates.OPENED;
const raceOpen = race => race.raceDrawState === raceDrawStates.OPENED;
const raceOngoing = race => race.raceDrawState === raceDrawStates.REGCLOSED || race.raceDrawState === raceDrawStates.CANCELCLOSED;
const raceNoLongerOpen = race => race.raceDrawState > raceDrawStates.OPENED;
const raceHasPreliminaryResults = race => race.raceDrawState >= raceDrawStates.RESULT_ENTERED;
const raceHasFinalResults = race => race.raceDrawState >= raceDrawStates.RESULT_VERIFIED;
const raceHasResult = race => race.raceDrawState >= raceDrawStates.RESULT_ENTERED;
const raceIsCancelable = race => race.raceDrawState <= raceDrawStates.REGCLOSED;
const racecardHasResults = racecard => racecard.some(meeting => meeting.raceSummaries.some(race => raceHasPreliminaryResults(race)));
const exported = {
  drawOpen,
  drawNotYetOpen,
  raceMoreThanDefined,
  raceNotYetOpen,
  raceOpen,
  raceOngoing,
  raceNoLongerOpen,
  raceHasPreliminaryResults,
  racecardHasResults,
  raceHasFinalResults,
  raceHasResult,
  raceIsCancelable
};
if (svs.isServer) {
  module.exports = exported;
} else {
  svs.racing.common.statusUtil = exported;
}

 })(window);