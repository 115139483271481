(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/common/assets/javascripts/util.js') >= 0) return;  svs.modules.push('/components/racing/common/assets/javascripts/util.js');

'use strict';

const {
  gameTypes,
  gameTypesList
} = svs.isServer ? require('./constants.es6') : svs.racing.common.constants;
const isValidGameType = gameType => gameTypesList.indexOf(gameType) > -1;
const getGameTypeSortOrder = draw => {
  switch (draw) {
    case gameTypes.TOPP5:
      return 0;
    case gameTypes.RX4IO:
      return 1;
    case gameTypes.RX3IOAO:
      return 2;
    case gameTypes.RX3AO:
      return 3;
    case gameTypes.RX3IO:
      return 4;
    case gameTypes.RX2P4:
      return 5;
    case gameTypes.RX2P:
      return 6;
    case gameTypes.RX2IO:
      return 7;
    case gameTypes.TOPP2:
      return 8;
    case gameTypes.VINNARE:
      return 9;
    case gameTypes.PLATS:
      return 10;
    default:
      return 0;
  }
};
const gameTypeHasXpert = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
    case gameTypes.RX4IO:
    case gameTypes.RX3IOAO:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
    case gameTypes.RX3AO:
    case gameTypes.TOPP2:
      return true;
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    case gameTypes.RX2IO:
    case gameTypes.RX3IO:
    default:
      return false;
  }
};
const gameTypeXpertMinParticipants = gameType => {
  switch (gameType) {
    case gameTypes.TOPP2:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
      return 7;
    case gameTypes.TOPP5:
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    case gameTypes.RX2IO:
    default:
      return -1;
  }
};
const gametypeHasIoDistribution = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
    case gameTypes.RX4IO:
    case gameTypes.RX3IOAO:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
    case gameTypes.RX3AO:
    case gameTypes.RX3IO:
      return true;
    case gameTypes.VINNARE:
    case gameTypes.TOPP2:
    case gameTypes.PLATS:
    case gameTypes.RX2IO:
    default:
      return false;
  }
};
const gameTypeIoDistributionDisplay = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
      return 5;
    case gameTypes.RX4IO:
      return 4;
    case gameTypes.RX3IOAO:
    case gameTypes.RX3IO:
      return 3;
    case gameTypes.TOPP2:
    case gameTypes.RX2IO:
      return 2;
    case gameTypes.RX3AO:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    default:
      return 1;
  }
};
const gameTypeHasMostPlayed = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
    case gameTypes.RX4IO:
    case gameTypes.TOPP2:
    case gameTypes.RX3IOAO:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
    case gameTypes.RX2IO:
    case gameTypes.RX3AO:
    case gameTypes.RX3IO:
      return true;
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    default:
      return false;
  }
};

const gameTypeHasQuinellaOdds = gameType => {
  switch (gameType) {
    case gameTypes.TOPP2:
      return true;
    case gameTypes.RX2IO:
    case gameTypes.TOPP5:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
    case gameTypes.RX4IO:
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    case gameTypes.RX3IO:
    default:
      return false;
  }
};
const gameTypeHasPressRatings = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
      return true;
    case gameTypes.TOPP2:
    case gameTypes.RX2IO:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
    case gameTypes.RX4IO:
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    case gameTypes.RX3IO:
    default:
      return false;
  }
};
const gameTypeHasSystem = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
    case gameTypes.RX4IO:
    case gameTypes.TOPP2:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
    case gameTypes.RX3IOAO:
    case gameTypes.RX3AO:
    case gameTypes.RX2IO:
    case gameTypes.RX3IO:
      return true;
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    default:
      return false;
  }
};
const gameTypeIsAnyOrder = gameType => {
  switch (gameType) {
    case gameTypes.TOPP2:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
    case gameTypes.RX3AO:
      return true;
    case gameTypes.TOPP5:
    case gameTypes.RX4IO:
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    case gameTypes.RX2IO:
    case gameTypes.RX3IO:
    default:
      return false;
  }
};
const gameTypeIsInOrder = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
    case gameTypes.RX4IO:
      return true;
    case gameTypes.TOPP2:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    case gameTypes.RX3AO:
    default:
      return false;
  }
};
const gameTypeIsExclusiveInOrder = gameType => {
  switch (gameType) {
    case gameTypes.RX2IO:
    case gameTypes.RX3IO:
      return true;
    case gameTypes.TOPP5:
    case gameTypes.RX4IO:
    case gameTypes.TOPP2:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    case gameTypes.RX3AO:
    default:
      return false;
  }
};
const gameTypeWindivIsTotalAnyOrder = (gameType, windiv) => gameType === gameTypes.TOPP5 && windiv === 2;
const gameTypeNumSelections = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
      return 5;
    case gameTypes.RX4IO:
      return 4;
    case gameTypes.RX3IOAO:
    case gameTypes.RX3AO:
    case gameTypes.RX3IO:
      return 3;
    case gameTypes.RX2P4:
    case gameTypes.RX2P:
    case gameTypes.TOPP2:
    case gameTypes.RX2IO:
      return 2;
    case gameTypes.VINNARE:
    case gameTypes.PLATS:
    default:
      return 1;
  }
};
const gameTypeNumResults = gameType => {
  switch (gameType) {
    case gameTypes.RX2P:
      return 3;
    case gameTypes.RX2P4:
      return 4;
    default:
      return gameTypeNumSelections(gameType);
  }
};
const getDrawOfGameType = (race, gameType) => {
  if (!race || !race.draws || !gameType || !isValidGameType(gameType)) {
    return false;
  }
  return race.draws.find(draw => draw.gameType === gameType);
};
const getDrawOfDefaultGameType = race => {
  if (!race || !race.draws) {
    return undefined;
  }
  const defaultDraw = race.draws.filter(draw => draw.gameType === gameTypes.TOPP5 || draw.gameType === gameTypes.VINNARE)[0];
  return defaultDraw ? defaultDraw : race.draws[0];
};

const getCancelledParticipants = participants => {
  const cancelledParticipants = [];
  participants.forEach(participant => {
    if (participant.canceled === true) {
      cancelledParticipants.push(participant.participantNumber);
    }
  });
  return cancelledParticipants;
};
const raceHasGameType = (race, gameType) => {
  if (!race || !race.draws || !gameType || !isValidGameType(gameType)) {
    return false;
  }
  return race.draws.findIndex(draw => draw.gameType === gameType) > -1;
};
const raceHasGameTypeXpert = (race, gameType) => {
  if (!gameTypeHasXpert(gameType) || !race || !race.participants) {
    return false;
  }
  const minParticipants = gameTypeXpertMinParticipants(gameType);
  if (minParticipants < 0) {
    return true;
  }
  const cancelledParticipants = getCancelledParticipants(race.participants);
  const numActiveParticipants = race.numberOfParticipants - cancelledParticipants.length;
  return numActiveParticipants >= minParticipants;
};
const raceDefaultGameType = (race, jackpotGameType) => {
  if (!race || !race.draws) {
    return undefined;
  }
  const validDraws = race.draws;
  let defaultDraw = validDraws.filter(draw => draw.gameType === gameTypes.TOPP5 || draw.gameType === jackpotGameType || draw.gameType === gameTypes.VINNARE)[0];
  if (!defaultDraw) {
    defaultDraw = validDraws[0] ? validDraws[0] : undefined;
  }
  return defaultDraw ? defaultDraw.gameType : undefined;
};
const imageExist = url => {
  const img = new Image();
  img.src = url;
  return img.height !== 0;
};
const wagerIsWheel = wager => {
  var _wager$wager, _wager$wager2, _wager$wager3;
  return (wager === null || wager === void 0 || (_wager$wager = wager.wager) === null || _wager$wager === void 0 || (_wager$wager = _wager$wager.bets[0]) === null || _wager$wager === void 0 || (_wager$wager = _wager$wager.rxBoards[0]) === null || _wager$wager === void 0 ? void 0 : _wager$wager.rxRaces[0].length) > 0 && (wager === null || wager === void 0 || (_wager$wager2 = wager.wager) === null || _wager$wager2 === void 0 || (_wager$wager2 = _wager$wager2.bets[0]) === null || _wager$wager2 === void 0 || (_wager$wager2 = _wager$wager2.rxBoards[0]) === null || _wager$wager2 === void 0 ? void 0 : _wager$wager2.rxRaces[1].length) > 0 && (wager === null || wager === void 0 || (_wager$wager3 = wager.wager) === null || _wager$wager3 === void 0 || (_wager$wager3 = _wager$wager3.bets[0]) === null || _wager$wager3 === void 0 || (_wager$wager3 = _wager$wager3.rxBoards[0]) === null || _wager$wager3 === void 0 ? void 0 : _wager$wager3.systemType[0]) === 'RX_NOPOS';
};
const exported = {
  getGameTypeSortOrder,
  isValidGameType,
  gametypeHasIoDistribution,
  gameTypeHasMostPlayed,
  gameTypeHasSystem,
  gameTypeHasXpert,
  gameTypeIoDistributionDisplay,
  gameTypeXpertMinParticipants,
  gameTypeHasQuinellaOdds,
  gameTypeHasPressRatings,
  gameTypeIsAnyOrder,
  gameTypeIsExclusiveInOrder,
  gameTypeIsInOrder,
  gameTypeWindivIsTotalAnyOrder,
  gameTypeNumSelections,
  gameTypeNumResults,
  getDrawOfGameType,
  getDrawOfDefaultGameType,
  raceHasGameType,
  raceHasGameTypeXpert,
  raceDefaultGameType,
  getCancelledParticipants,
  wagerIsWheel,
  imageExist
};
if (svs.isServer) {
  module.exports = exported;
} else {
  svs.racing.common.util = exported;
}

 })(window);