(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/common/assets/javascripts/strings.js') >= 0) return;  svs.modules.push('/components/racing/common/assets/javascripts/strings.js');
"use strict";


const {
  coatColors,
  currencyTypes,
  finishPlaceReasons,
  gaits,
  gameTypes,
  raceDirections,
  raceDisciplines,
  raceStatuses,
  sexTypes,
  startTypes,
  trackConditions,
  pressRatingTypes
} = svs.isServer ? require('./constants.es6') : svs.racing.common.constants;
const getGameTypeHelpShort = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
      return 'Tippa de fem första hästarna i rätt ordning';
    case gameTypes.RX4IO:
      return 'Tippa de fyra första hästarna i rätt ordning';
    case gameTypes.RX3IOAO:
      return 'Tippa de tre första hästarna i rätt ordning';
    case gameTypes.VINNARE:
      return 'Tippa vinnaren i loppet';
    case gameTypes.PLATS:
      return 'Tippa en av de tre första i mål';
    case gameTypes.TOPP2:
      return 'Tippa de två första utan inbördes ordning';
    case gameTypes.RX2IO:
      return 'Tippa de två första hästarna i rätt ordning';
    case gameTypes.RX2P:
      return 'Tippa två av de tre första i mål';
    case gameTypes.RX2P4:
      return 'Tippa två av de fyra första i mål';
    case gameTypes.RX3AO:
      return 'Tippa de tre första utan inbördes ordning';
    case gameTypes.RX3IO:
      return 'Tippa de tre första hästarna i rätt ordning';
    default:
      return '';
  }
};
const getGameTypeHelpHeader = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
      return 'Ett lopp - fem hästar';
    case gameTypes.RX4IO:
      return 'Ett lopp - fyra hästar';
    case gameTypes.RX3IOAO:
    case gameTypes.RX3AO:
    case gameTypes.RX3IO:
      return 'Ett lopp - tre hästar';
    case gameTypes.TOPP2:
    case gameTypes.RX2IO:
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
      return 'Ett lopp - två hästar';
    case gameTypes.VINNARE:
      return 'Ett lopp - en häst';
    case gameTypes.PLATS:
      return 'Ett lopp - en häst av topp tre';
    default:
      return '';
  }
};
const getProductName = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
      return 'High Five';
    case gameTypes.RX4IO:
      return 'High 4';
    case gameTypes.TOPP2:
      return 'Duo';
    case gameTypes.RX2IO:
      return 'Duo Rak';
    case gameTypes.RX2P:
      return '2 av 3';
    case gameTypes.RX2P4:
      return '2 av 4';
    case gameTypes.VINNARE:
      return 'Vinnare';
    case gameTypes.PLATS:
      return 'Plats';
    case gameTypes.RX3IOAO:
      return 'High 3';
    case gameTypes.RX3AO:
      return 'Trippel';
    case gameTypes.RX3IO:
      return 'Trippel Rak';
    default:
      return undefined;
  }
};
const getProductUrlName = gameType => {
  switch (gameType) {
    case gameTypes.TOPP5:
      return 'high-five';
    case gameTypes.RX4IO:
      return 'high-4';
    case gameTypes.RX3IOAO:
      return 'high-3';
    case gameTypes.TOPP2:
      return 'duo';
    case gameTypes.RX2IO:
      return 'duo-rak';
    case gameTypes.RX2P:
      return '2-av-3';
    case gameTypes.RX2P4:
      return '2-av-4';
    case gameTypes.VINNARE:
      return 'vinnare';
    case gameTypes.PLATS:
      return 'plats';
    case gameTypes.RX3AO:
      return 'Trippel';
    case gameTypes.RX3IO:
      return 'trippel-rak';
    default:
      return undefined;
  }
};
const getRaceStatus = raceStatus => {
  switch (raceStatus) {
    case raceStatuses.STARTED:
      return raceStatus;
    default:
      return undefined;
  }
};
const getGait = gait => {
  switch (gait) {
    case gaits.GALLOP:
      return 'Galopp';
    case gaits.TROTTING:
      return 'Trav';
    default:
      return undefined;
  }
};
const getRaceDiscipline = raceDiscipline => {
  switch (raceDiscipline) {
    case raceDisciplines.FLAT:
      return 'Slätlöpning';
    case raceDisciplines.HURDLES:
      return 'Häcklöpning';
    case raceDisciplines.TROTTING:
      return 'Trav';
    case raceDisciplines.STEEPLECHASE:
      return 'Steeplechase';
    case raceDisciplines.OBSTACLE:
      return 'Hinderlopp';
    case raceDisciplines.HARNESSED:
      return 'Sulkylopp';
    case raceDisciplines.CROSS:
      return 'Cross Country';
    case raceDisciplines.RIDDEN:
      return 'Monté';
    default:
      return undefined;
  }
};
const getStartType = startType => {
  switch (startType) {
    case startTypes.AUTO:
      return 'Auto';
    case startTypes.VOLT:
      return 'Volt';

    case startTypes.STALLS:
      return 'Översättning';
    default:
      return undefined;
  }
};
const getSexType = sexType => {
  switch (sexType.toLowerCase()) {
    case sexTypes.FEMALE:
      return 'Sto';
    case sexTypes.GELDING:
      return 'Valack';
    case sexTypes.MALE:
      return 'Hingst';
    default:
      return undefined;
  }
};
const getCoatColor = coatColor => {
  switch (coatColor) {
    case coatColors.BAY:
      return 'Brun';
    case coatColors.BAY_ROAN:
      return 'Stickelhårig brun';
    case coatColors.BLACK:
      return 'Svar';
    case coatColors.BROWN:
      return 'Mörkbrun';
    case coatColors.CHESTNUT:
      return 'Fux';
    case coatColors.CHESTNUT_ROAN:
      return 'Stickelhårig fux';
    case coatColors.LIVER_CHESTNUT:
      return 'Mörk fux';
    case coatColors.LIGHT_GREY:
    case coatColors.GREY:
      return 'Skimmel';
    case coatColors.ROAN:
      return 'Stickelhårig';
    default:
      return '-';
  }
};
const getCurrencyType = currencyType => {
  switch (currencyType) {
    case currencyTypes.EUR:
      return '€';
    case currencyTypes.HKD:
      return 'HK$';
    case currencyTypes.SEK:
      return 'kr';
    case currencyTypes.USD:
      return '$';
    default:
      return undefined;
  }
};
const getRaceDescription = function (race) {
  let showDiscipline = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let addDicipline = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const array = [];
  const addToDesc = text => {
    if (text) {
      array.push(text);
    }
  };
  addToDesc(showDiscipline ? getRaceDiscipline(race.raceDiscipline) : getGait(race.gait));
  addToDesc(addDicipline ? getRaceDiscipline(race.raceDiscipline) : undefined);
  addToDesc(getStartType(race.startType));
  addToDesc(race.distanceString);
  return array.join(' | ');
};
const getStatisticsNoDataMsg = () => 'Avsaknad av data kan bero på starter utanför Frankrike eller att hästen är debutant.';
const getTrackCondition = trackCondition => {
  switch (trackCondition.toLowerCase()) {
    case trackConditions.SOFT:
    case trackConditions.QUITE_SOFT:
    case trackConditions.YIELDING:
      return 'Mjuk';
    case trackConditions.GOOD_TO_SOFT:
      return 'Bra till mjuk';
    case trackConditions.VERY_SOFT:
      return 'Mycket mjuk';
    case trackConditions.GOOD:
      return 'Bra';
    case trackConditions.HARD:
    case trackConditions.FIRM:
      return 'Hård';
    case trackConditions.HOLDING:
      return 'Kladdig';
    case trackConditions.HEAVY:
      return 'Tung';
    case trackConditions.VERY_HEAVY:
      return 'Mycket tung';
    case trackConditions.GOOD_TO_FIRM:
      return 'Bra till hård';
    case trackConditions.PSF_LENTE:
      return 'Långsam';
    case trackConditions.FAST:
    case trackConditions.PSF_RAPIDE:
      return 'Snabb';
    case trackConditions.PSF_STANDARD:
      return 'Normal';
    case trackConditions.PSF_TRES_RAPIDE:
      return 'Mycket snabb';
    default:
      return '';
  }
};
const getRaceDirection = raceDirection => {
  switch (raceDirection) {
    case raceDirections.COUNTERCLOCKWISE:
      return 'Motsols';
    case raceDirections.CLOCKWISE:
      return 'Medsols';
    default:
      return '';
  }
};
const getResultText = (place, status, canceled) => {
  status = status || '';
  if (canceled) {
    return 'STR';
  }
  switch (status) {
    case finishPlaceReasons.DISQUALIFIED:
      return 'DIS';
    case finishPlaceReasons.FALLEN:
      return 'FÖLL';
    case finishPlaceReasons.TURNED_BACK:
    case finishPlaceReasons.STOPPED:
    case finishPlaceReasons.REMAINED_AT_THE_POST:
    case finishPlaceReasons.RUN_OUT:
      return 'UTG';
    case finishPlaceReasons.DISTANCED:
    case finishPlaceReasons.BEATEN:
      return '0';
    case finishPlaceReasons.FINISHED:
      if (!place) {
        return '0';
      } else if (place < 0) {
        return '-';
      }
      return "".concat(place, ":a");
    default:
      {
        return '-';
      }
  }
};

const getKilometerTime = kilometerTime => {
  if (!kilometerTime) {
    return '-';
  }
  const splittedTimeArr = kilometerTime.split("'");
  return "".concat(splittedTimeArr[1], ",").concat(splittedTimeArr[3]);
};
const getStorageKey = (raceId, gameType, systemType) => {
  if (!raceId || !gameType || !systemType) {
    return undefined;
  }
  return "".concat(raceId, ".").concat(gameType, ".").concat(systemType);
};
function buildQuinellaOddsLookupKey(firstParticipantNumber, secondParticipantNumber) {
  return "".concat(firstParticipantNumber, "-").concat(secondParticipantNumber);
}
function getPressRatingTypeName(pressRatingType) {
  switch (pressRatingType) {
    case pressRatingTypes.FAVOURITES:
      return 'Favoriter';
    case pressRatingTypes.OUTSIDERS:
      return 'Utmanare';
    case pressRatingTypes.OTHERS:
      return 'Övriga';
    default:
      return '';
  }
}
const exported = {
  getGameTypeHelpShort,
  getCoatColor,
  getProductName,
  getGait,
  getProductUrlName,
  getRaceDescription,
  getRaceDiscipline,
  getRaceStatus,
  getStatisticsNoDataMsg,
  getStartType,
  getSexType,
  getTrackCondition,
  getRaceDirection,
  getCurrencyType,
  getResultText,
  getKilometerTime,
  getStorageKey,
  buildQuinellaOddsLookupKey,
  getGameTypeHelpHeader,
  getPressRatingTypeName
};
if (svs.isServer) {
  module.exports = exported;
} else {
  svs.racing.common.strings = exported;
}

 })(window);