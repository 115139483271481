(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/common/assets/javascripts/wager-client-error.js') >= 0) return;  svs.modules.push('/components/racing/common/assets/javascripts/wager-client-error.js');
"use strict";


function getWagerClientError(response, errorCode) {
  switch (errorCode) {
    case 100005:
      return {
        title: 'Du har blivit utloggad',
        errMessage: 'Vänligen logga in igen.'
      };
    case 3112:
      return {
        title: 'Betalningen misslyckades',
        errMessage: 'Just nu har vi problem med att ansluta till vår samarbetspartner, vänligen försök igen senare.'
      };
    case 8103:
      return {
        title: 'Struken häst',
        errMessage: 'Spelet går inte att lägga eftersom en eller flera av dina valda hästar har strukits.'
      };
    case 8105:
      return {
        title: 'Stängt för spel',
        errMessage: 'Loppet har startat och vi tar inte emot fler spel.'
      };
    case 8107:
      return {
        title: 'Stängt för spel',
        errMessage: 'Loppet har inte öppnat för spel, vänligen försök senare.'
      };
    case 8124:
      return {
        title: 'Stängt för spel',
        errMessage: 'Just nu går det inte att lägga detta spel, vänligen försök senare.',
        callback: () => {
          window.location.replace(svs.core.urlMapping.get('racingArena'));
        }
      };
    default:
      return {
        title: 'Något gick tyvärr fel',
        errMessage: 'Prova igen om en stund.'
      };
  }
}
svs.racing.common.getWagerClientError = getWagerClientError;

 })(window);