(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/common/assets/javascripts/urls.js') >= 0) return;  svs.modules.push('/components/racing/common/assets/javascripts/urls.js');

'use strict';

const cdn2 = svs.isServer ? require('trinidad-core').core.options.cdn2 : svs.core.config.data.svsconfig.cdn2;
const getSilksUrl = (silks, silksFormattedDate) => "".concat(cdn2, "/pmu/").concat(silksFormattedDate, "/dress/").concat(silks, ".png");
const getTrackUrl = (track, trackFormattedDate) => "".concat(cdn2, "/pmu/").concat(trackFormattedDate, "/course/").concat(track, ".png");
const getPhotoFinishUrl = (track, formattedDate) => "".concat(cdn2, "/pmu/").concat(formattedDate, "/photo-finish/").concat(track, ".jpg");
const exported = {
  getSilksUrl,
  getTrackUrl,
  getPhotoFinishUrl
};
if (svs.isServer) {
  module.exports = exported;
} else {
  svs.racing.common.urls = exported;
}

 })(window);